{
  "account.activate.message.error": "Link aktywacyjny jest niepoprawny lub wygasł.",
  "account.activate.message.success": "Twoje konto zostało aktywowane.",
  "account.login.form.forot-password": "Nie pamiętasz hasła?",
  "account.login.form.forgot-password-2": "Nie pamiętam hasła",
  "account.login.form.not-registered": "Nie zarejestrowany?",
  "account.login.form.register-now": "Załóż konto!",
  "account.login.form.login": "Email",
  "account.login.form.password": "Hasło",
  "account.login.login.title": "Zaloguj się",
  "account.login.multidevice.info-1": "Pamiętaj!",
  "account.login.multidevice.info-2": "Do sklepu GTV jak i do aplikacji mobilnej GTV Bus, logujesz się tym samym adresem e-mail oraz hasłem.",
  "account.login.rigister.description.1": "Zarejestruj się przez formularz i bądź z nami w kontakcie!",
  "account.login.rigister.description.2": "Pamiętaj że, dzięki rejestracji:",
  "account.login.rigister.list.1": "Otrzymasz bezpłatne konto pasażera",
  "account.login.rigister.list.2": "Zyskasz dostęp do najnowszych ofert i promocji",
  "account.login.rigister.list.3": "Otrzymasz pełny wgląd w historię swoich zamówień",
  "account.login.rigister.title": "Nie masz jeszcze konta? Nic straconego!",
  "account.popup.back-to-login": "Powrót do logowania",
  "account.popup.forgot-password": "Zresetuj hasło",
  "account.popup.forgot-password.form.email": "Email",
  "account.popup.forgot-password.title": "Resetowanie hasła",
  "account.popup.login.form.login": "Login",
  "account.popup.login.form.password": "Hasło",
  "account.popup.login.title": "Logowanie",
  "account.popup.register": "Utwórz konto",
  "account.popup.register.form.login": "E-mail",
  "account.popup.register.form.password": "Hasło",
  "account.popup.register.title": "Rejestracja",
  "account.register.description": "Wystarczy że, wypełnisz powyższe pola. Pozostałe informacje możesz podać później.",
  "account.register.form.regulation": "Rgulamin",
  "account.register.form.select-all": "Zaznacz wszystkie",
  "account.register.success.description": "Aby dokończyć rejestracje konta należy potwierdzić to klikając w link podany na adres: ",
  "account.register.success.title": "Dziekujemy za założenie konta",
  "account.register.why-join": "Dlaczego warto dołączyć do GTV Bus?",
  "account.resend-activation-link.message.success": "Link aktywcyjny został ponownie wysłany",
  "alert.error.booking-order-excceeded": "Niestety wybrany przejazd nie jest dostępny. Proszę o kontakt pod numer +48 606 200 002",
  "alert.error.booking-order-limit-excceeded": "Osiągnięto limit pasażerów w wybranej trasie.",
  "alert.error.generic": "Wystąpił nieoczekiwany błąd",
  "alert.error.generic-with-contact": "Wystąpił nieoczekiwany błąd. Proszę o kontakt pod numer +48 606 200 002",
  "alert.error.generic.not-found": "Nie można odnaleźć zasobu",
  "alert.error.paymnet-overdue": "Termin płatności za zamówienie upłynął. Proszę skontaktuj się z biurem obsługi klienta.",
  "alerts.mydata.change.success": "Dane zostały poprawnie zmienione",
  "alerts.password.change.success": "Hasło zostało poprawnie zmienione",
  "auth.token.expired": "Twoja sesja wygasła",
  "booking.confirmation.account.cutomer.type.company": "Firma",
  "booking.confirmation.account.cutomer.type.person": "Osoba prywatna",
  "booking.confirmation.account.options.login": "Mam już konto i chcę się zalogować",
  "booking.confirmation.account.options.register": "Chcę przy okazji rezerwacji założyć konto",
  "booking.confirmation.account.options.without-account": "Rezerwacja bez zakładania konta",
  "booking.confirmation.billing.note.long": "Informujemy, że zgodnie z obowiązującymi przepisami, NIE MA możliwości wystawienia faktury VAT na firmę, jeżeli dane do faktury (w tym NIP firmy) nie będą podane na etapie składania zamówienia.",
  "booking.confirmation.billing.title": "Dane do faktury",
  "booking.confirmation.customer.details": "Dane do rezerwacji:",
  "booking.confirmation.customer.invoice": "Dane do faktury:",
  "booking.confirmation.discount.form.code.applied": "Zastosowany kupon",
  "booking.confirmation.discount.form.code.placeholder": "Kod rabatowy",
  "booking.confirmation.fail.description": "Ponów płatność w zakładce Moje konto / Moje rezerwacje lub skontaktuj się z naszym BOK tel.: +48 606 200 002, mail: info@gtvbus.pl",
  "booking.confirmation.fail.title": "Podczas płatności wystąpił błąd",
  "booking.confirmation.optional.comments.placeholder": "Wpisz treść wiadomości",
  "booking.confirmation.optional.comments.switch.description": "Zaznacz pole, jeśli chcesz przekazać nam jakieś istotne informacje odnośnie zamówienia",
  "booking.confirmation.optional.comments.switch.title": "Uwagi do rezerwacji (opcjonalnie)",
  "booking.confirmation.optional.title": "Opcjonalnie",
  "booking.confirmation.party.form.city": "Miasto",
  "booking.confirmation.party.form.companyName": "Nazwa firmy",
  "booking.confirmation.party.form.country": "Kraj",
  "booking.confirmation.party.form.email": "Email",
  "booking.confirmation.party.form.email.helptext": "Na podany adres wyślemy bilet",
  "booking.confirmation.party.form.firstname": "Imię",
  "booking.confirmation.party.form.firstname-and-lastname": "Imię i nazwisko",
  "booking.confirmation.party.form.lastname": "Nazwisko",
  "booking.confirmation.party.form.nip": "NIP",
  "booking.confirmation.party.form.phone": "Telefon",
  "booking.confirmation.party.form.phone.helptext": "SMS tylko w przypadku opóźnienia lub zmiany trasy",
  "booking.confirmation.party.form.postalCode": "Kod pocztowy",
  "booking.confirmation.party.form.street": "Ulica i numer",
  "booking.confirmation.party.mirror-passenger": "Chcę podać inne dane dla pasażera niż rezerwującego",
  "booking.confirmation.party.title": "Dane rezerwującego",
  "booking.confirmation.party.title.passenger": "{{ i }}. Pasażer",
  "booking.confirmation.payment.provider.description.payu-ap": "Płatność kartą zapisaną na koncie Google Pay",
  "booking.confirmation.payment.provider.description.payu-blik": "Płatność kodem BLIK",
  "booking.confirmation.payment.provider.description.payu-c": "Płatność po podaniu danych karty płatniczej",
  "booking.confirmation.payment.provider.description.payu-dpp": "Płatność odroczona do 30 dni",
  "booking.confirmation.payment.provider.description.payu-dpt": "Płatność odroczona do 30 dni",
  "booking.confirmation.payment.provider.description.payu-jp": "Płatność kartą zapisaną w portfelu Apple Pay",
  "booking.confirmation.payment.provider.description.payu-pw": "Płatność przelewem online z polskiego konta bankowego",
  "booking.confirmation.payment.provider.description.stripe-bancontact": "Płatność przelewem online z belgijskiego konta bankowego",
  "booking.confirmation.payment.provider.description.stripe-ideal": "Płatność przelewem online z holenderskiego konta bankowego",
  "booking.confirmation.payment.provider.description.transfer-transfer": "Płatność przelewem na numer konta bankowego",
  "booking.confirmation.payment.provider.payu-ap": "Google Pay",
  "booking.confirmation.payment.provider.payu-blik": "Blik",
  "booking.confirmation.payment.provider.payu-c": "Płatność kartą",
  "booking.confirmation.payment.provider.payu-dpp": "Zapłać później",
  "booking.confirmation.payment.provider.payu-dpt": "Twisto zapłać później",
  "booking.confirmation.payment.provider.payu-jp": "Apple Pay",
  "booking.confirmation.payment.provider.payu-pw": "Szybki przelew",
  "booking.confirmation.payment.provider.stripe-bancontact": "Bancontact",
  "booking.confirmation.payment.provider.stripe-ideal": "Ideal",
  "booking.confirmation.payment.provider.transfer-transfer": "Przelew tradycyjny",
  "booking.confirmation.register.form.email": "Powtórz adres email",
  "booking.confirmation.register.form.password": "Hasło",
  "booking.confirmation.register.form.password.helptext": "Hasło do konta",
  "booking.confirmation.register.title": "Rejestracji konta",
  "booking.confirmation.status.confirmed": "Rezerwacja oczekuje na płatność",
  "booking.confirmation.status.paid": "Rezerwacja została już opłacona",
  "booking.confirmation.success.bank-transfer.description.1": "Jednocześnie informujemy, że warunkiem realizacji przejazdu będzie jego opłacenie.",
  "booking.confirmation.success.bank-transfer.description.2": "W przypadku nieopłacenia przejazdu rezerwacja zostanie anulowana.",
  "booking.confirmation.success.bank-transfer.details.1": "Prosimy o dokonanie wpłaty na konto:",
  "booking.confirmation.success.bank-transfer.details.2": "GTV Office Sp. z o.o. ul. Hutnicza 5 46-040 Ozimek",
  "booking.confirmation.success.bank-transfer.details.3.EUR": "Numer rachunku dla wpłat EUR: ",
  "booking.confirmation.success.bank-transfer.details.3.PLN": "Numer rachunku dla wpłat PLN: ",
  "booking.confirmation.success.bank-transfer.details.4": "ING Bank Śląski",
  "booking.confirmation.success.bank-transfer.details.5": "SWIFT KOD: INGBPLPW",
  "booking.confirmation.success.bank-transfer.details.6": "Szczegóły dotyczące kwoty i tytułu przelewu znajdziesz w otrzymanej wiadomości e-mail.",
  "booking.confirmation.success.bank-transfer.details.7": "Warunkiem odnotowania dokonanej płatności jest wysłanie potwierdzenia przelewu na maila: <a href=\"mailto:przelew@gtvbus.pl\">przelew@gtvbus.pl</a>",
  "booking.confirmation.success.bank-transfer.number.EUR": "23 1050 1504 1000 0090 8133 6084",
  "booking.confirmation.success.bank-transfer.number.PLN": "45 1050 1504 1000 0090 8133 6076",
  "booking.confirmation.success.description": "Potwierdzenie rezerwacji wraz z biletem wyślemy na adres podany podczas rezerwacji",
  "booking.confirmation.success.title": "Twoja rezerwacja została pomyślnie zrealizowana",
  "booking.confirmation.summary.reservation": "Twoja rezerwacja",
  "booking.confirmation.summary.total-include-tax": "Łącznie (w tym VAT)",
  "booking.confirmation.summary.x.adults": "{{ x }} x pasażer",
  "booking.confirmation.summary.x.options-name": "{{ x }} x {{ name }}",
  "booking.confirmation.summary.x.options-name-discount": "Kod rabatowy <span class='text-secondary'>{{ name }}</span>",
  "booking.confirmation.title.additional-options": "Opcje dodatkowe",
  "booking.confirmation.title.payment": "Płatność",
  "booking.confirmation.title.reservation-details": "Dane do rezerwacji",
  "booking.find.no-address-found": "Nie znaleziono adresu",
  "booking.nav.parcel": "Wyślij paczkę",
  "booking.nav.passengers": "Zarezerwuj przejazd",
  "booking.option.type.1.negative.name": "Przejazd",
  "booking.option.type.1.positive.name": "Przejazd",
  "booking.option.type.2.negative.name": "Dodatkowy bagaż",
  "booking.option.type.2.positive.name": "Dodatkowy bagaż",
  "booking.option.type.3.negative.name": "Rabat",
  "booking.option.type.3.positive.name": "Dopłata",
  "booking.option.type.4.negative.name": "Paczka",
  "booking.option.type.4.positive.name": "Paczka",
  "booking.option.type.6.negative.name": "Gwarancja zwrotu ceny za bilet",
  "booking.option.type.6.positive.name": "Gwarancja zwrotu ceny za bilet",
  "booking.options.2.0.alternative.description": "Bagaż (w cenie biletu)",
  "booking.options.2.0.description": "Nie wybrano dodatkowych sztuk bagażu do 20 kg",
  "booking.options.2.1.alternative.description": "1 bagaż podręczny 5 kg + maksymalnie 2 sztuki bagażu o łącznej wadze do 40 kg",
  "booking.options.2.1.description": "{{ count }} dodatkowa sztuka bagażu do 20 kg",
  "booking.options.2.2-4.description": "{{ count }} dodatkowe sztuki bagażu do 20 kg",
  "booking.options.2.5+.description": "{{ count }} dodatkowych sztuk bagażu do 20 kg",
  "booking.options.2.title": "Dodatkowy bagaż dla pasażerów",
  "booking.options.6.description": "Szczegóły dotyczące gwarancji znajdą Państwo w Regulaminie",
  "booking.options.6.title": "Gwarancja zwrotu ceny za bilet",
  "booking.options.passenger-no": "Pasażer {{ index }}",
  "booking.passenger.calendar.no-connections": "Brak połączeń",
  "booking.passenger.calendar.price.from": "od {{ price }}",
  "booking.passenger.calendar.price.price-from": "Cena od {{ price }}",
  "booking.passenger.return": "Powrót",
  "booking.passenger.search.add": "Dodaj",
  "booking.passenger.search.adults": "Osoba",
  "booking.passenger.search.amount": "Ilość osób",
  "booking.passenger.search.amount.informations.link": "Zobacz więcej",
  "booking.passenger.search.amount.informations.text": "Informacje na temat zniżek i taryf",
  "booking.passenger.search.amount.input.1": "{{ amount }} osoba",
  "booking.passenger.search.amount.input.2-4": "{{ amount }} osoby",
  "booking.passenger.search.amount.input.5-9": "{{ amount }} osób",
  "booking.passenger.search.animals": "Zwierzę",
  "booking.passenger.search.children": "Dziecko",
  "booking.passenger.search.depart": "Odjazd",
  "booking.passenger.search.from": "Skąd",
  "booking.passenger.search.return": "Powrót",
  "booking.passenger.search.to": "Dokąd",
  "booking.passenger.transit": "Przejazd",
  "booking.passenger.transit.arrival": "Przyjazd do punktu:",
  "booking.passenger.transit.depart": "Odjazd z punktu:",
  "booking.passenger.transit.depart.from": "Odjazd z",
  "booking.passenger.transit.arrival.to": "Przyjazd do",
  "booking.passenger.transit.depart.isPoint": "Odjazd z przystanku:",
  "booking.passenger.transit.arrival.isPoint": "Przyjazd do przystanku:",
  "booking.passenger.transit.depart-after-midnight": "Odjazd odbywa się po północy dnia następnego.",
  "booking.passenger.transit.depart-before-midnight": "Odjazd odbywa się przed północą dnia poprzedniego.",
  "booking.passenger.transit.description": "Prosimy o kontakt z biurem obsługi klienta.",
  "booking.passenger.transit.from": "Z {{ address }}",
  "booking.passenger.transit.mail": "mail: ",
  "booking.passenger.transit.not-found": "Taką trasę przejazdu obsługujemy po konsultacji telefonicznej z naszą infolinią.",
  "booking.passenger.transit.or": "lub",
  "booking.passenger.transit.phone": "Telefon: ",
  "booking.passenger.transit.phone.number.href": "+48606200002",
  "booking.passenger.transit.phone.number.text": "+48 606 200 002",
  "booking.passenger.transit.price.total": "Łącznie",
  "booking.passenger.transit.selected-depart-transit": "Wybrana rezerwacja przejazdu",
  "booking.passenger.transit.to": "Do {{ address }}",
  "booking.status.expired": "Twój koszyk wygasł, rozpocznij rezerwację od początku",
  "booking.status.no-access": "Błędne lub nieaktywne zamówienie",
  "button.activate": "Aktywuj",
  "button.add": "Dodaj",
  "button.add-discount-code": "Dodaj kod rabatowy",
  "button.apply": "Zastosuj",
  "button.back": "Powrót",
  "button.back-to-main-page": "Wróć do strony głównej",
  "button.book": "Rezerwuj",
  "button.booking-avaialble-count": "Dostępne miejsca: {{ count }}",
  "button.buy-and-pay": "Kupuje i płacę",
  "button.collapse": "Zwiń",
  "button.continue": "Kontynuuj",
  "button.create-account": "Utwórz konto",
  "button.edit": "Edytuj",
  "button.edit-booking": "Edytuj rezerwację",
  "button.expand": "Rozwiń",
  "button.limit-reached": "Brak wolnych miejsc",
  "button.login": "Zaloguj się",
  "button.logout-anyway": "Wyloguj",
  "button.no": "Nie",
  "button.password-reset": "Ustaw hasło",
  "button.register": "Zarejestruj się",
  "button.remove-discount-code": "Usuń kod rabatowy",
  "button.reset-password": "Zresetuj hasło",
  "button.save": "Zapisz",
  "button.search-connections": "Szukaj połączeń",
  "button.select": "Wybierz",
  "button.yes": "Tak",
  "calendar.button.delete": "Usuń",
  "calendar.button.passenger.transit.details": "Szczegóły trasy",
  "calendar.button.passenger.transit.edit-show": "Edytuj",
  "calendar.button.passenger.transit.edit-hide": "Ukryj",
  "calendar.button.passenger.transit.map-show": "Pokaż mapę",
  "calendar.button.passenger.transit.map-hide": "Ukryj mapę",
  "calendar.months": "Miesiące",
  "calendar.weekday.short.0": "Niedz.",
  "calendar.weekday.short.1": "Pon.",
  "calendar.weekday.short.2": "Wt.",
  "calendar.weekday.short.3": "Śr.",
  "calendar.weekday.short.4": "Czw.",
  "calendar.weekday.short.5": "Pt.",
  "calendar.weekday.short.6": "Sob.",
  "calendar.years": "Lata",
  "country.AUT": "Austria",
  "country.BEL": "Belgia",
  "country.DEU": "Niemcy",
  "country.NLD": "Holandia",
  "country.POL": "Polska",
  "country.ROU": "Rumunia",
  "country.UKR": "Ukraina",
  "currency.dropdown.select-currency": "Wybierz walutę",
  "currency.dropdown.select-language": "Wybierz język",
  "currency.select-currency": "Wybierz walutę",
  "currency.select-currency-and-language": "Wybierz język i walutę",
  "footer.copyright": "© GTV Bus. All Rights Reserved",
  "footer.nav.for-companies": "Dla firm",
  "footer.nav.how-to-buy": "Wideo → Jak kupić przejazd",
  "footer.nav.privacy-policy": "Polityka prywatności",
  "footer.nav.recruitment": "Rekrutacja",
  "footer.nav.regulations": "Regulamin",
  "footer.powered-by": "Powered by",
  "footer.social-media": "Social media",
  "forms.error.message.email": "Niepoprawna adres email",
  "forms.error.message.general": "Niepoprawna wartość pola",
  "forms.errors.message.nip": "Niepoprawna wartość pola",
  "forms.error.message.invalid_address": "Podaj nazwę miejscowości, ulicy oraz numer domu",
  "forms.error.message.invalid_lookup_id": "Nie można pobrać adresu",
  "forms.error.message.invalid_return_date": "Niepoprawna data powrotu",
  "forms.error.message.invalid_street": "Podaj nazwę ulicy oraz numer domu",
  "forms.error.message.invalid_street_number": "Podaj numer domu",
  "forms.error.message.max_length": "Maksymalna ilość znaków to {{ max_length }}",
  "forms.error.message.new_password": "Nowe hasło musi być takie samo w obu polach",
  "forms.error.message.postal-code": "Niepoprawny kod pocztowy",
  "forms.error.message.register_email_not_match": "Adres email nie jest zgodny z adresem osoby rezerwującej",
  "forms.error.message.required": "To pole jest wymagane",
  "forms.error.message.words": "W treści pola występuje niedozwolone znaki",
  "header.nav.help": "Pomoc",
  "header.nav.safety": "Bezpieczeństwo",
  "heremaps.form.address-not-found": "Nie mogę znaleźć adresu",
  "heremaps.form.address.from": "Wpisz adres",
  "heremaps.form.address.to": "Wpisz adres",
  "heremaps.form.city": "Miejscowość",
  "heremaps.form.nothing-found": "Nie znaleziono adresu",
  "heremaps.form.number": "Nr",
  "heremaps.form.street": "Ulica",
  "label.busstops": "Przystanki",
  "language.pl-PL": "PL",
  "language.ua-UA": "UA",
  "location.detection": "Wykrywanie lokalizacji",
  "location.detection.access.denied": "Odmówiono dostępu do lokalizacji",
  "location.detection.found.title": "Wykryto lokalizację",
  "location.detection.found.want-to-use-it": "Czy chcesz jej użyć?",
  "location.detection.not-found.title": "Nie wykryto lokalizacji",
  "login.message.error.invalid-credentials": "Email lub hasło są nieprawidłowe",
  "login.message.error.not-activated": "Konto nie zostało aktywowane.",
  "login.message.error.not-activated-link": "Wyślij ponownie email z linkiem aktywacyjnym",
  "login.message.success.log-in": "Zalogowano poprawnie",
  "logout.message.error": "Wystąpił błąd podaczas wylogowania",
  "logout.message.success.logged-out": "Wylogowano poprawnie",
  "order.exception.not_found": "Nie znaleziono zamówienia",
  "register.title": "Rejestracja",
  "regulations.checkbox.accept": "Zapoznałem się i akceptuję",
  "regulations.checkbox.accept.and": "oraz",
  "regulations.checkbox.accept.privacy-policy": "Politykę Prywatności",
  "regulations.checkbox.accept.regulation": "Regulamin Serwisu",
  "regulations.checkbox.accept.service": "Serwisu*",
  "regulations.checkbox.marketing.1.long": "Wyrażam zgodę na przetwarzanie moich danych osobowych w celach marketingowych zgodnie z Art. 6 ust. 1 lit. a ogólnego rozporządzenia o ochronie danych osobowych z dnia 27 kwietnia 2016 r. (Dz. Urz. UE L 119 z 04.05.2016) oraz ustawą o ochronie danych osobowych z 10 maja 2018 roku (Dz.U. 2018r., poz.1000) przez GTV Office Sp. z o.o.",
  "regulations.checkbox.marketing.1.short": "Wyrażam zgodę na przetwarzanie moich danych osobowych w celach marketingowych",
  "regulations.checkbox.marketing.2.long": "Wyrażam zgodę na marketing telefoniczny za pomocą telekomunikacyjnych urządzeń końcowych (w tym telefonu) i automatycznych systemów wywołujących, w tym w szczególności na wskazany przeze mnie numer telefonu, w celach marketingu bezpośredniego GTV Office Sp. z o.o. zgodnie z art. 172 ust. 1 ustawy z dnia 16 lipca 2004 Prawo telekomunikacyjne.",
  "regulations.checkbox.marketing.2.short": "Wyrażam zgodę na marketing telefoniczny",
  "reset-password.message.error": "Link jest niepoprawny lub wygasł.",
  "reset-password.message.success": "Nowe hasło zostało zapisane.",
  "title": "GTV PL",
  "user.agreements.content": "Zaznacz pola i ciesz się pełnią przywilejów jakie daje Ci konto GTV. Pamiętaj, nie spamujemy i dbamy o Twój komfort, nie tylko w trasie ale także przed ekranem telefonu czy komputera :) Zaznaczenie powiadomień, to także pewność, że wszystkie niezbędne informacje trafią do Ciebie na czas.",
  "user.agreements.title": "Zgody na powiadomienia",
  "user.alerts.add": "Dane zostały poprawnie dodane",
  "user.alerts.delete": "Dane zostały poprawnie usunięte",
  "user.alerts.update": "Dane zostały poprawnie zmienione",
  "user.delete.content.1": "Jeśli chcesz usunąć swoje konto, skontaktuj się z nami mailowo na adres ",
  "user.delete.content.2": "Uwaga! Usunięcie konta spowoduje bezpowrotne utracenie wszystkich danych, w tym: zapisanych adresów, historii zamówień oraz uniemożliwi logowanie się do aplikacji pasażera. Konta usuniętego nie można reaktywować, dane usuwane są bezpowrotnie.",
  "user.delete.title": "Usuń konto",
  "user.logout.confirmation.button.no": "Nie",
  "user.logout.confirmation.button.yes": "Tak",
  "user.logout.confirmation.title": "Czy na pewno chcesz się wylogować?",
  "user.logout.content": "Klikając w przycisk poniżej, zostaniesz wylogowany z serwisu. Nie będziesz mogła/mógł korzystać z jego funkcji w tym: zapisanych adresów, historii i składania zamówień.",
  "user.logout.title": "Wyloguj z serwisu",
  "user.mydata.account": "Konto",
  "user.mydata.active_from": "Aktywne od {{ date }}",
  "user.mydata.birthdate": "Data urodzenia",
  "user.mydata.birthdate.text": "Podaj nam datę urodzenia a my dzięki temu, będziemy mogli wysyłać Ci urodzinowe „co nieco”.",
  "user.mydata.button.edit": "Edytuj",
  "user.mydata.button.submit": "Zmień",
  "user.mydata.firstname": "Imię",
  "user.mydata.fullname": "Imię i nazwisko",
  "user.mydata.lastname": "Nazwisko",
  "user.mydata.login": "Mail (login)",
  "user.mydata.name_surname": "Imię i nazwisko",
  "user.mydata.phone": "Telefon",
  "user.mydata.set_birthdate": "Ustaw datę urodzenia",
  "user.mydata.set_phone": "Ustaw numer telefonu",
  "user.mydata.title": " Moje dane",
  "user.password.new": "Nowe hasło",
  "user.password.new_repeat": "Powtórz nowe hasło",
  "user.password.old": "Stare hasło",
  "user.password.submit": "Zmień hasło",
  "user.password.title": "Zmiana hasła",
  "user.reservation.buttons.delete": "Usuń",
  "user.reservation.buttons.edit": "Edytuj",
  "user.reservation.buttons.new": "Dodaj dane nowej osoby rezerwującej",
  "user.reservation.buttons.no": "Nie",
  "user.reservation.buttons.save": "Zapisz",
  "user.reservation.buttons.yes": "Tak",
  "user.reservation.delete.title": "Czy chcesz usunąć wybrane dane do rezerwacji?",
  "user.reservation.form.city": "Miasto",
  "user.reservation.form.company_name": "Nazwa firmy",
  "user.reservation.form.country": "Kraj",
  "user.reservation.form.email": "Email",
  "user.reservation.form.firstname": "Imię",
  "user.reservation.form.is_company": "Firma",
  "user.reservation.form.is_private": "Osoba prywatna",
  "user.reservation.form.lastname": "Nazwisko",
  "user.reservation.form.nip": "NIP",
  "user.reservation.form.phone": "Telefon",
  "user.reservation.form.postal_code": "Kod pocztowy",
  "user.reservation.form.street": "Ulica i numer",
  "user.reservation.form.title_invoice": "Dane do faktury",
  "user.reservation.invoice": "Dane do faktury",
  "user.reservation.title": "Dane do rezerwacji",
  "user.reservations.buttons.pay": "Opłać",
  "user.reservations.buttons.search": "Szukaj połączenia",
  "user.reservations.buttons.ticket": "Pobierz fakturę/bilet",
  "user.reservations.headers.cost": "Koszt",
  "user.reservations.headers.number": "Numer zamówienia",
  "user.reservations.headers.order-date": "Data zakupu",
  "user.reservations.headers.passengers": "Pasażerowie",
  "user.reservations.headers.payment-status": "Status płatności",
  "user.reservations.headers.price": "Cena",
  "user.reservations.headers.return": "Powrót",
  "user.reservations.headers.route": "Przejazd",
  "user.reservations.headers.services": "Usługi",
  "user.reservations.headers.ticket": "Bilet i faktura",
  "user.reservations.no-transits-found": "Nie znaleziono przejazdów",
  "user.reservations.none": "Brak",
  "user.reservations.sevices.insurance": "Ubezpieczenie",
  "user.reservations.sevices.luggage": "Dodatkowy bagaż",
  "user.reservations.sevices.route": "Przejazd",
  "user.reservations.status.canceled": "Anulowane",
  "user.reservations.status.payed": "Opłacone",
  "user.reservations.status.unpayed": "Nieopłacone",
  "user.reservations.tabs.incoming": "Przejazdy nadchodzące",
  "user.reservations.tabs.realized": "Przejazdy zrealizowane",
  "user.tabs.reservations": "Moje rezerwacje",
  "user.tabs.settings": "Ustawienia konta",
  "user.title": "Moje konto",
  "booking.passenger.statement.underaged.title": "Pamiętaj!",
  "booking.confirmation.party.underaged": "Osoba niepełnoletnia",
  "booking.passenger.statement.underaged.1": "Dla dziecka poniżej 150 cm wzrostu musisz zapewnić fotelik/podkładkę.",
  "booking.passenger.statement.underaged.2": "Dziecko do 12 roku życia może podróżować tylko z opiekunem.",
  "booking.passenger.statement.underaged.3a": "Dziecko powyżej 12 roku życia może podróżować bez opiekuna jedynie po wypełnieniu przez Ciebie oświadczenia.",
  "booking.passenger.statement.underaged.3b": "Pobierz oświadczenie",
  "booking.passenger.statement.underaged.3c": ".",
  "booking.options.unavailable.title": "Uwaga!",
  "booking.options.unavailable.contact": "W celu zakupu tej opcji dodatkowej prosimy o kontakt telefoniczny z infolinią pod numerem ",
  "booking.options.unavailable.contact.href": "+48606200002",
  "booking.options.unavailable.contact.text": "+48 606 200 002",
  "button.reload-page": "odśwież bieżącą stronę",
  "alert.error.generic.comma": "Wystąpił nieoczekiwany błąd, ",
  "booking.passenger.search.address.incomplete": "Aby dokonać rezerwacji podaj nazwę ulicy oraz numer domu",
  "booking.passenger.search.place": "Miejscowość",
  "calendar.from.bus-stop": "Z przystanku",
  "calendar.from.point": "Z punktu",
  "          v2          ": "          v2          ",
  "        - v2 -        ": "        - v2 -        ",
  "       -- v2 --       ": "       -- v2 --       ",
  "      --- v2 ---      ": "      --- v2 ---      ",
  "     ---- v2 ----     ": "     ---- v2 ----     ",
  "    ----- v2 -----    ": "    ----- v2 -----    ",
  "   ------ v2 ------   ": "   ------ v2 ------   ",
  "  ------- v2 -------  ": "  ------- v2 -------  ",
  " -------- v2 -------- ": " -------- v2 -------- ",
  "--------  v2 ---------": "--------  v2 ---------",
  "nav.how-to-book": "Jak zarezerwować?",
  "nav.luggage": "Bagaż",
  "nav.security": "Bezpieczeństwo",
  "nav.local-distribution": "Rozkład lokalny",
  "nav.contact": "Kontakt",
  "nav.faq": "FAQ",
  "header.auth.login": "Zaloguj się",
  "header.shop.auth.login": "Zaloguj się",
  "header.shop.auth.register": "Zarejestruj się",
  "home.banner.text.1": "Dowozimy!",
  "home.banner.text.2": " - wiemy jak!",
  "home.contact.text.1": "Masz pytania?",
  "home.contact.text.2a": "Obsługa klienta czynna 7 dni w tygodniu",
  "home.contact.text.2b": "od 8:00 do 21:00",
  "home.contact.text.call-us": "Zadzwoń do nas",
  "home.contact.text.phone-number": "+48 606 200 002",
  "nav.footer.for-companies": "Dla firm",
  "nav.footer.video": "Wideo - Jak kupić przejazd",
  "nav.footer.recruitment": "Rekrutacja",
  "nav.footer.affiliate-program": "Program afiliacyjny",
  "nav.footer.gtv-bus-group": "GTV BUS GROUP",
  "search.widget.title": "Wyszukaj przejazd",
  "search.widget.directions.one-way": "W jedną stronę",
  "search.widget.directions.two-way": "W obie strony",
  "search.widget.form.button.search": "Szukaj",
  "search.widget.form.button.search-connections": "Szukaj połączeń",
  "search.widget.form.address.from.label": "Skąd jedziesz?",
  "search.widget.form.address.from.placeholder": "Wpisz adres",
  "search.widget.form.address.from.label-city": "Wpisz miasto",
  "search.widget.form.address.from.placeholder-city": "Z jakiego miasta jedziesz?",
  "search.widget.form.address.from.label-street": "Wpisz ulicę",
  "search.widget.form.address.from.placeholder-street": "Ulica",
  "search.widget.form.address.from.label-housenumber": "Wpisz numer domu",
  "search.widget.form.address.from.placeholder-housenumber": "Numer domu",
  "search.widget.form.address.to.label": "Dokąd jedziesz?",
  "search.widget.form.address.to.placeholder": "Wpisz adres",
  "search.widget.form.address.to.label-city": "Wpisz miasto",
  "search.widget.form.address.to.placeholder-city": "Do jakiego miasta jedziesz?",
  "search.widget.form.address.to.label-street": "Wpisz ulicę",
  "search.widget.form.address.to.placeholder-street": "Ulica",
  "search.widget.form.address.to.label-housenumber": "Wpisz numer domu",
  "search.widget.form.address.to.placeholder-housenumber": "Numer domu",
  "search.widget.form.address.button.my-location": "Użyj mojej lokalizacji",
  "search.widget.form.address.button.cannot-find": "Nie mogę znaleźć adresu",
  "search.widget.form.address-not-found": "Nie znaleziono adresu",
  "search.widget.form.depart.label": "Wyjazd",
  "search.widget.form.depart.placeholder": "Wybierz datę",
  "search.widget.form.return.label": "Powrót",
  "search.widget.form.return.placeholder": "Wybierz datę",
  "search.widget.form.address.incomplete": "Aby dokonać rezerwacji podaj nazwę ulicy oraz numer domu",
  "btn.delete": "Usuń",
  "search.widget.form.passengers.label": "Ile osób",
  "search.widget.form.passengers-count.label": "Ile osób?",
  "search.widget.form.passengers-count.option.person": "Osoba",
  "search.widget.form.passengers-count.value.passengers.0": "{{ count }} osób",
  "search.widget.form.passengers-count.value.passengers.1": "{{ count }} osoba",
  "search.widget.form.passengers-count.value.passengers.2-4": "{{ count }} osoby",
  "search.widget.form.passengers-count.value.passengers.5+": "{{ count }} osób",
  "calendar.month.0": "stycznia",
  "calendar.month.1": "lutego",
  "calendar.month.2": "marca",
  "calendar.month.3": "kwietnia",
  "calendar.month.4": "maja",
  "calendar.month.5": "czerwca",
  "calendar.month.6": "lipca",
  "calendar.month.7": "sierpnia",
  "calendar.month.8": "września",
  "calendar.month.9": "października",
  "calendar.month.10": "listopada",
  "calendar.month.11": "grudnia",
  "calendar.month.short.0": "sty.",
  "calendar.month.short.1": "lut.",
  "calendar.month.short.10": "lis.",
  "calendar.month.short.11": "gru.",
  "calendar.month.short.2": "mar.",
  "calendar.month.short.3": "kwi.",
  "calendar.month.short.4": "maj.",
  "calendar.month.short.5": "cze.",
  "calendar.month.short.6": "lip.",
  "calendar.month.short.7": "sie.",
  "calendar.month.short.8": "wrz.",
  "calendar.month.short.9": "paź.",
  "calendar.month.short.-": "?",
  "calendar.weekday.letters.0": "nd",
  "calendar.weekday.letters.1": "pn",
  "calendar.weekday.letters.2": "wt",
  "calendar.weekday.letters.3": "śr",
  "calendar.weekday.letters.4": "czw",
  "calendar.weekday.letters.5": "pt",
  "calendar.weekday.letters.6": "sob",
  "calendar.weekday.letter.0": "Nd",
  "calendar.weekday.letter.1": "P",
  "calendar.weekday.letter.2": "W",
  "calendar.weekday.letter.3": "Ś",
  "calendar.weekday.letter.4": "Cz",
  "calendar.weekday.letter.5": "P",
  "calendar.weekday.letter.6": "S",
  "calendar.weekday.0": "Niedziela",
  "calendar.weekday.1": "Poniedziałek",
  "calendar.weekday.2": "Wtorek",
  "calendar.weekday.3": "Środa",
  "calendar.weekday.4": "Czwartek",
  "calendar.weekday.5": "Piątek",
  "calendar.weekday.6": "Sobota",
  "btn.apply": "Zastosuj",
  "forms.errors.message.email": "Niepoprawna adres email",
  "forms.errors.message.general": "Niepoprawna wartość pola",
  "forms.errors.message.invalid-address": "Podaj nazwę miejscowości, ulicy oraz numer domu",
  "forms.errors.message.invalid-lookup_id": "Nie można pobrać adresu",
  "forms.errors.message.invalid-return-date": "Niepoprawna data powrotu",
  "forms.errors.message.invalid-street": "Podaj nazwę ulicy oraz numer domu",
  "forms.errors.message.invalid-street_number": "Podaj numer domu",
  "forms.errors.message.invalidPhoneNumber": "Podaj prawidłowy numer telefonu",
  "forms.errors.message.max_length": "Maksymalna ilość znaków to {{ max_length }}",
  "forms.errors.message.new_password": "Nowe hasło musi być takie samo w obu polach",
  "forms.errors.message.postal-code": "Niepoprawny kod pocztowy",
  "forms.errors.message.register_email_not_match": "Adres email nie jest zgodny z adresem osoby rezerwującej",
  "forms.errors.message.required": "To pole jest wymagane",
  "forms.errors.message.words": "W treści pola występuje niedozwolone znaki",
  "forms.errors.message.words-numbers": "W treści pola powinne występować tylko liczby i litery",
  "forms.errors.message.must-match": "Te pola muszą być jednakowe",
  "forms.errors.message.text": "Niepoprawna wartość pola",
  "forms.errors.message.phone-number": "Podaj prawidłowy numer telefonu",
  "booking.banner.information.two-way": "w obie strony",
  "booking.banner.information.one-way": "w jedną stronę",
  "booking.banner.btn.edit-search": "Edytuj wyszukiwanie",
  "booking.depart.title": "Zarezerwuj pierwszy przejazd",
  "booking.return.title": "Zarezerwuj przejazd powrotny",
  "booking.calendar.no-connections": "Brak połączeń",
  "booking.calendar.price-from": "od",
  "booking.details.transits.title": "Dostępne przejazdy",
  "booking.details.search.title": "Szczegóły wyszukiwania",
  "booking.details.search.address.depart": "Z adresu",
  "booking.details.search.address.return": "Na adres",
  "booking.transit.pin.from.bus-stop": "Z przystanku",
  "booking.transit.pin.from.point": "Z adresu",
  "booking.transit.pin.to.bus-stop": "Na przystanek",
  "booking.transit.pin.to.point": "Na adres",
  "booking.transit.summary.title": "Łącznie za przejazd",
  "btn.change": "Zmień",
  "btn.book": "Rezerwuj",
  "booking.transit.price.per-person": "Cena na osobę",
  "btn.booking-avaialble-count": "Dostępne miejsca: {{ count }}",
  "btn.limit-reached": "Brak wolnych miejsc",
  "btn.edit-reservation": "Zmień rezerwację",
  "btn.transit.show-details": "Pokaż szczegóły dotyczące przejazdu",
  "booking.transit.selected.form.address.incomplete": "Aby dokonać rezerwacji podaj nazwę ulicy oraz numer domu",
  "btn.continue": "Kontynuuj",
  "nav.hello": "Cześć",
  "account.login.register.description.1": "Zarejestruj się przez formularz i bądź z nami w kontakcie!",
  "account.login.register.description.2": "Pamiętaj że, dzięki rejestracji:",
  "account.login.register.list.1": "Otrzymasz bezpłatne konto pasażera",
  "account.login.register.list.2": "Zyskasz dostęp do najnowszych ofert i promocji",
  "account.login.register.list.3": "Otrzymasz pełny wgląd w historię swoich zamówień",
  "account.login.register.list.description.1": "Kolejne rezerwacje będą dużo wygodniejsze, bez konieczności ponownego uzupełniania Twoich danych. Otrzymasz też dostęp do wyjątkowych benefitów.",
  "account.login.register.list.description.2": "Tylko zarejestrowane osoby mogą korzystać z wielu atrakcyjnych benefitów, jak tańsze przejazdy i atrakcyjne promocje lub konkursy.",
  "account.login.register.list.description.3": "W łatwy sposób znajdziesz terminy, ceny swoich wyjazdów, sprawdzisz rachunek czy rozliczysz płatność.",
  "account.login.register.title": "Nie masz jeszcze konta? Nic straconego!",
  "booking.summary.total": "Łączna cena za przejazdy",
  "btn.booking.create-draft": "Wprowadź dane pasażera",
  "title.order.reservation-details": "Dane do rezerwacji",
  "title.order.additinal-options": "Opcje dodatkowe",
  "title.order.payment-methods": "Sposoby płatności",
  "order.customer.firstname.label": "Imię",
  "order.customer.lastname.label": "Nazwisko",
  "order.customer.email.label": "Adres e-mail",
  "order.customer.phone.label": "Numer telefonu",
  "order.customer.subtitle": "Dane rezerwującego",
  "order.customer.invoice.subtitle": "Dane do faktury",
  "order.customer.invoice.type.person": "Osoba prywatna",
  "order.customer.invoice.type.company": "Firma",
  "order.customer.invoice.information": "Informujemy, że zgodnie z obowiązującymi przepisami, NIE MA możliwości wystawienia faktury VAT na firmę, jeżeli dane do faktury (w tym NIP firmy) nie będą podane na etapie składania zamówienia.",
  "order.customer.company-name.label": "Nazwa firmy",
  "order.customer.country.label": "Kraj",
  "order.customer.street.label": "Ulica i numer",
  "order.customer.postal-code.label": "Kod pocztowy",
  "order.customer.city.label": "Miasto",
  "order.customer.nip.label": "NIP",
  "order.customer.nip.button": "Pobierz dane z GUS",
  "order.customer.passenger.title": "{{ count }}. Pasażer",
  "order.customer.passenger.firstname.label": "Imię",
  "order.customer.passenger.lastname.label": "Nazwisko",
  "order.customer.passenger.phone.label": "Numer telefonu",
  "order.card.passenger.minor.label": "Osoba niepełnoletnia",
  "order.card.passenger.minor.description.1": "Dla dziecka poniżej 150 cm wzrostu musisz zapewnić fotelik/podkładkę.",
  "order.card.passenger.minor.description.2": "Dziecko do 12 roku życia może podróżować tylko z opiekunem.",
  "order.card.passenger.minor.description.3": "Dziecko powyżej 12 roku życia może podróżować bez opiekuna jedynie po wypełnieniu przez Ciebie oświadczenia.",
  "order.card.passenger.minor.description.3.1": "Pobierz oświadczenie.",
  "order.card.passenger.differ.label": "Chcę podać inne dane dla pasażera niż rezerwującego",
  "order.customer.comment.title": "Uwagi dla kierowcy",
  "order.customer.comment.description": "Zaznacz pole, jeśli chcesz przekazać nam jakieś istotne informacje odnośnie zamówienia.",
  "order.customer.comment.checkbox.label": "Chcę dodać uwagi dla kierowcy",
  "order.customer.comment.textarea.label": "Wpisz treść wiadomości...",
  "order.options.2.title": "Dodatkowy bagaż",
  "order.options.2.description": "Możliwość dodania dodatkowej sztuki bagażu <b>do 20 kg</b> (na pasażera).",
  "order.options.2.switch.label": "Dodaj dodatkowy bagaż dla pasażerów",
  "order.options.2.box.1.title": "Bagaże wliczone w cenę biletu na pasażera:",
  "order.options.2.box.list.1a.title": "1x bagaż podręczny",
  "order.options.2.box.list.1a.description": "do 5 kg",
  "order.options.2.box.list.1b.title": "1x bagaż główny",
  "order.options.2.box.list.1b.description": "maksymalnie 2 sztuki bagażu do 40 kg łącznie, szerokość + wysokość + głębokość: max. 165 cm",
  "order.options.2.box.2.title": "Bagaż niestandardowy*:",
  "order.options.2.box.list.2a.title": "Nietypowy bagaż?",
  "order.options.2.box.list.2a.description": "Istnieje możliwość przewozu <b>psów do 15kg, kotów i małych zwierząt domowych</b>, jak i <b>bagaży o wymiarach niestandardowych</b>, w celu ustalenia szczegółów prosimy kontaktować się z infolinią pod numerem <b>+48 606 200 002</b>",
  "order.options.2.box.2.helptext": "*Po ustaleniu z przewoźnikiem",
  "order.options.6.title": "Gwarancja zwrotu ceny za bilet",
  "order.options.6.switch.label": "Dodaj gwarancję zwrotu ceny za bilet",
  "order.options.6.description": "Szczegóły dotyczące gwarancji znajdą Państwo w Regulaminie",
  "order.option.depart.title": "Pierwszy przejazd",
  "order.option.return.title": "Przejazd powrotny",
  "order.option.route.from.title": "Z: <b>{{ city }}</b>, {{ street }}",
  "order.option.route.to.title": "Do: <b>{{ city }}</b>, {{ street }}",
  "order.option.route.passenger.name": "Pasażer {{ index }}",
  "order.payment.provider.description.payu-ap": "Płatność kartą zapisaną na koncie Google Pay",
  "order.payment.provider.description.payu-blik": "Płatność kodem BLIK",
  "order.payment.provider.description.payu-c": "Płatność po podaniu danych karty płatniczej",
  "order.payment.provider.description.payu-dpp": "Płatność odroczona do 30 dni",
  "order.payment.provider.description.payu-dpt": "Płatność odroczona do 30 dni",
  "order.payment.provider.description.payu-jp": "Płatność kartą zapisaną w portfelu Apple Pay",
  "order.payment.provider.description.payu-pw": "Płatność przelewem online z polskiego konta bankowego",
  "order.payment.provider.description.stripe-bancontact": "Płatność przelewem online z belgijskiego konta bankowego",
  "order.payment.provider.description.stripe-ideal": "Płatność przelewem online z holenderskiego konta bankowego",
  "order.payment.provider.description.transfer-transfer": "Płatność przelewem na numer konta bankowego",
  "order.payment.provider.title.payu-ap": "Google Pay",
  "order.payment.provider.title.payu-blik": "Blik",
  "order.payment.provider.title.payu-c": "Płatność kartą",
  "order.payment.provider.title.payu-dpp": "Zapłać później",
  "order.payment.provider.title.payu-dpt": "Twisto zapłać później",
  "order.payment.provider.title.payu-jp": "Apple Pay",
  "order.payment.provider.title.payu-pw": "Szybki przelew",
  "order.payment.provider.title.stripe-bancontact": "Bancontact",
  "order.payment.provider.title.stripe-ideal": "Ideal",
  "order.payment.provider.title.transfer-transfer": "Przelew tradycyjny",
  "order.summary.title": "Twoja rezerwacja",
  "order.option.type.1.negative.name": "bilet",
  "order.option.type.1.positive.name": "bilet",
  "order.option.type.2.negative.name": "dodatkowy bagaż",
  "order.option.type.2.positive.name": "dodatkowy bagaż",
  "order.option.type.3.negative.name": "rabat",
  "order.option.type.3.positive.name": "usługa dodatkowa",
  "order.option.type.4.negative.name": "paczka",
  "order.option.type.4.positive.name": "paczka",
  "order.option.type.6.negative.name": "gwarancja zwrotu ceny za bilet",
  "order.option.type.6.positive.name": "gwarancja zwrotu ceny za bilet",
  "order.option.type.7.negative.name": "rabat",
  "order.option.type.7.positive.name": "usługa dodatkowa",
  "order.summary.total.title.1": "Łącznie",
  "order.summary.total.title.2": "(w tym VAT)",
  "order.summary.discount.button.label": "Mam kupon rabatowy",
  "btn.activate": "Aktywuj",
  "order.summary.form.discount.input.label": "Wpisz kod rabatowy",
  "order.summary.form.discount.info.text.1": "Zastosowano kupon rabatowy. Poprzednia cena: ",
  "order.summary.form.discount.info.text.2": ". Oszczędzasz ",
  "btn.buy": "Kupuję i płacę",
  "order.summary.discount.applied.text": "Poprawnie zastosowano kod rabatowy ",
  "order.confirmation.success.title": "Twoja rezerwacja została pomyślnie zrealizowana",
  "order.confirmation.success.description": "Potwierdzenie rezerwacji wraz z biletem wyślemy na adres podany podczas rezerwacji",
  "order.confirmation.fail.title": "Podczas płatności wystąpił błąd",
  "order.confirmation.fail.description": "Ponów płatność w zakładce Moje konto / Moje rezerwacje lub skontaktuj się z naszym BOK tel.: +48 606 200 002, mail: info@gtvbus.pl",
  "btn.back-to-main-page": "Wróć do strony głównej",
  "order.confirmation.success.bank-transfer.description.1": "Jednocześnie informujemy, że warunkiem realizacji przejazdu będzie jego opłacenie.",
  "order.confirmation.success.bank-transfer.description.2": "W przypadku nieopłacenia przejazdu rezerwacja zostanie anulowana.",
  "order.confirmation.success.bank-transfer.details.1": "Prosimy o dokonanie wpłaty na konto:",
  "order.confirmation.success.bank-transfer.details.2": "GTV Office Sp. z o.o. ul. Hutnicza 5 46-040 Ozimek",
  "order.confirmation.success.bank-transfer.details.3.EUR": "Numer rachunku dla wpłat EUR: ",
  "order.confirmation.success.bank-transfer.details.3.PLN": "Numer rachunku dla wpłat PLN: ",
  "order.confirmation.success.bank-transfer.details.4": "ING Bank Śląski",
  "order.confirmation.success.bank-transfer.details.5": "SWIFT KOD: INGBPLPW",
  "order.confirmation.success.bank-transfer.details.6": "Szczegóły dotyczące kwoty i tytułu przelewu znajdziesz w otrzymanej wiadomości e-mail.",
  "order.confirmation.success.bank-transfer.details.7": "Warunkiem odnotowania dokonanej płatności jest wysłanie potwierdzenia przelewu na maila: <a href=\"mailto:przelew@gtvbus.pl\">przelew@gtvbus.pl</a>",
  "order.confirmation.success.bank-transfer.number.EUR": "23 1050 1504 1000 0090 8133 6084",
  "order.confirmation.success.bank-transfer.number.PLN": "45 1050 1504 1000 0090 8133 6076",
  "btn.register": "Zarejestruj się",
  "account.register.title": "Zarejestruj się",
  "account.register.already-registered": "Masz już konto?",
  "account.register.form.login-now": "Zaloguj się!",
  "account.request-reset-password.description": "W celu zresetowania hasła wpisz swój adres e-mail podany podczas rejestracji. W ciągu chwili otrzymasz na niego link resetujący Twoje hasło. Jeżeli wiadomość nie pojawi się w folderze „Odebrane”, sprawdź inne foldery na swojej poczcie, np. Spam, Oferty, Powiadomienia itp.",
  "account.request-reset-password.success.description": "Sprawdź pocztę e-mail, aby znaleźć link do zresetowania hasła. Jeśli nie pojawi się w ciągu kilku minut, sprawdź folder ze spamem.",
  "account.request-reset-password.success.title": "Zresetuj hasło",
  "account.request-reset-password.title": "Zresetuj hasło",
  "btn.request-password-reset": "Wyślij link",
  "account.reset-password.form.password": "Hasło",
  "account.reset-password.title": "Ustaw nowe hasło",
  "order.options.5.title": "Gwarancja zwrotu ceny za bilet",
  "order.options.5.switch.label": "Dodaj gwarancję zwrotu ceny za bilet",
  "order.options.5.description": "Szczegóły dotyczące gwarancji znajdą Państwo w Regulaminie",
  "language.select.ro-RO": "Rumuński",
  "language.select.pl-PL": "Polski",
  "language.select.ua-UA": "Ukraiński",
  "calendar.mixed_ride": "Przejazd poranny",
  "calendar.mixed_ride.info.1": "Przejazd jest realizowany w formule Bus – Autokar – Bus.",
  "calendar.mixed_ride.info.2": "Więcej informacji na stronie <a class=\"tw-text-primary tw-underline\" href=\"https://gtvbus.pl/poranne-przejazdy/\" target=\"_blank\" > www.gtvbus.pl/poranne-przejazdy</a>",
  "search.widget.form.calendar-range.one-way.label": "Wyjazd",
  "search.widget.form.calendar-range.two-way.label": "Daty przejazdów",
  "search.widget.form.calendar-range.one-way.placeholder": "Wybierz datę",
  "search.widget.form.calendar-range.two-way.placeholder": "Wybierz daty",
  "btn.back": "Powrót",
  "btn.remove-selection": "Usuń wybór",
  "search.widget.form.calendar-range.popup.select-depart-date": "Wybierz datę wyjazdu",
  "search.widget.form.calendar-range.popup.depart": "Wyjazd",
  "search.widget.form.calendar-range.popup.select-return-date": "Wybierz datę powrotu",
  "search.widget.form.calendar-range.popup.return": "Powrót",
  "btn.confirm-selection": "Zatwierdź wybór",
  "btn.edit": "Edytuj",
  "btn.edit-close": "Zamknij edycję",
  "btn.close": "Zamknij",
  "booking.banner.information.1": "{{ value }} pasażer",
  "booking.banner.information.x": "{{ value }} pasażerów",
  "order.transit.selected.depart.title": "Wybrany pierwszy przejazd",
  "order.transit.selected.return.title": "Wybrany drugi przejazd",
  "menu.mobile.login": "Zaloguj się",
  "menu.mobile.panel": "Moje konto",
  "mobile.menu.item.reservation": "Jak zarezerwować?",
  "mobile.menu.item.luggage": "Bagaż",
  "mobile.menu.item.help": "Pomoc",
  "mobile.menu.item.local-timetable": "Rozkład lokalny",
  "mobile.menu.item.contact": "Kontakt",
  "mobile.menu.item.local-timetable.czestochowa": "Częstochowa",
  "mobile.menu.item.local-timetable.gliwice": "Gliwice",
  "mobile.menu.item.local-timetable.kedzierzyn-kozle": "Kędzierzyn-Koźle",
  "mobile.menu.item.local-timetable.opole": "Opole",
  "mobile.menu.item.local-timetable.title": "Rozkład lokalny",
  "mobile.menu.item.language": "Język i waluta",
  "mobile.menu.item.language.title": "Zmiana języka i waluty",
  "mobile.menu.item.language.select-language": "Wybierz język",
  "mobile.menu.item.language.select-currency": "Wybierz walutę",
  "btn.set-password": "Ustaw hasło",
  "order.register.title": "Śledź swoje przejazdy z GTV Bus.",
  "btn.create-account": "Załóż konto",
  "order.register.login.text": "Masz już swoje konto?",
  "order.register.login.btn": "Zaloguj się",
  "order.customers.list.details.title": "Dane do rezerwacji",
  "order.customers.list.invoice.title": "Dane do faktury",
  "booking.edit.title": "Edytuj wyszukiwanie",
  "btn.edit-search": "Edytuj wyszukiwanie",
  "booking.search.alert.no-transits-found": "Niestety dnia {{ date }} wybrany przejazd nie jest dostępny. Oto najbliższy termin:",
  "btn.save": "Zapisz",
  "btn.change-password": "Zmień hasło",
  "user.mydata.set_name": "Imię i nazwisko",
  "forms.errors.message.maxlength": "Zbyt długa wartość pola",
  "busstop-info.title": "Drogi Kliencie,",
  "busstop-info.content-1": "Informujemy, że rezerwowany przez Ciebie przejazd dotyczy kursu realizowanego autokarem na trasie z przystanku do przystanku. Sprawdź szczegóły swojej trasy i ciesz się wygodną podróżą!",
  "busstop-info.content-2": "Dziękujemy, że wybierasz nasze usługi!"
}
